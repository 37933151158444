import React from 'react'
import ReactTooltip from 'react-tooltip'

export default function PayTooltip ({ children }) {
  return(
    <>
      <button style={{ all: 'unset', cursor: 'pointer' }} data-tip data-for='payTooltip'>
        {children}
      </button>
      
      <ReactTooltip id='payTooltip' place='top' effect='solid' backgroundColor='black'>
        Total number of vacation and personal days per year
      </ReactTooltip>
    </>
  )
}