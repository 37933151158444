import { useStaticQuery, graphql } from "gatsby"

export const useCoefficients = () => {
  const { coefficients } = useStaticQuery(graphql`
    query getCoefficients {
      coefficients {
        Intercept
        log_pay
        paidsalary
        insure_employ
        bene_retire
        pto_prop35
        underemp
        overemp
        volatility
        advance
        daysvary
        Meaningful
        Autonomy
        Exhausting
        Superfair
        Support
        Safe
        Secure
        Training
        Opportunities5
        union_di
        W2emp
        age_cen
        age_sq
        woman
        Asian
        Black
        Latin
        anydisability
        Parent
        Chicago
        Suburbs
        PRODUCTION
        DISTRIBUTION
        HOSPITALITY
        HEALTHCARE
        EDU_PUBAD
        OTH_SERVICE_IND
        SALES_ADMIN
        PROD_TRANS_REPAIR
        OTH_SERVICE_OCC
      }
    }
  `)

  return coefficients
}