import React from 'react'

export default function IndicatorCheckbox ({ label, value, onChange }) {
  const setCheckboxSelection = (e) => {

    // e.target.value returns a string and not a boolean
    // set isTrue to true if e.target.value string === 'true' 
    // else isTrue will be false
    const isTrue = (e.target.value === 'true')
    onChange(isTrue)
  }

  return (
    <div className='px-4 mt-4 d-flex justify-content-start'>
      <label className='h5'>{label}</label>
      <div onChange={setCheckboxSelection} className='d-flex flex-row ml-4'>
        <div className='mx-1'>
          <input type="radio" name={label} value='true' id={`${label}-true`} checked={value === true} />
          <label for={`${label}-true`} className='mx-1'> Yes</label>
        </div>
        <div className='mx-3'>
          <input type="radio" name={label} value='false' id={`${label}-false`} checked={value === false} />
          <label for={`${label}-false`} className='mx-1'> No</label>
        </div>
      </div>
    </div>
  )
}