import { useStaticQuery, graphql } from "gatsby"

export const useSurveyData = () => {
  const data = useStaticQuery(graphql`
    query getSurveyData {
      allSurveyData {
        totalCount
        nodes {
          Added
          Age
          Age_bin4
          Age_bin6
          Asian
          Autonomy
          BIZ_TECH
          Black
          Chicago
          Citizenship
          Class
          Clopen
          Control
          Cut
          DISTRIBUTION
          Disrespected
          EDU_PUBAD
          EQnow
          Edu_bin4
          Exhausting
          Gender_bin3
          HEALTHCARE
          HOSPITALITY
          Happiness
          Harassed
          Health
          Hourspref_cat
          Hourspref_num
          Income_bin10
          Income_bin4
          Industry_bin19
          Industry_bin7
          Intense
          Jobsatisfact
          Jobtype
          Jobtype_bin3
          Latin
          Maxhours
          Meaningful
          Minhours
          Notice
          Monthlypay
          Mistreated
          OTH_SERVICE_IND
          OTH_SERVICE_OCC
          Occupation_bin16
          Occupation_bin4
          Opportunities
          Opportunities5
          PRODUCTION
          PROD_TRANS_REPAIR
          PRO_MGR
          PTOdays
          Parent
          Paytype_bin4
          Race_bin5
          Region
          Repetitive
          SALES_ADMIN
          Safe
          Schedstatus_bin3
          Secure
          Sickdays
          Sleep
          Student
          Suburbs
          Supercheck
          Superfair
          Supervise
          Support
          Takeoffwork
          Training
          Typicalhours
          Union
          Unionvote
          W2emp
          Wage
          White
          Workhome
          Withholding
          Worklife
          advance
          age_cen
          age_sq
          anydisability
          ba_or_more
          bene_health
          bene_parental
          bene_retire
          bene_sick
          bene_trans
          buyout
          daysvary
          ft_student
          gigemployer
          hours_mismatch
          hs_or_less
          input
          insure_employ
          insure_pubaid
          log_pay
          multijob
          oncall
          overemp
          paidcommission
          paidsalary
          paidtips
          paidwage
          pension
          pref_hrs_dev
          pref_hrs_dev_bin5
          pto_prop35
          retireaccount
          snap
          socsecure
          standshift
          underemp
          uninsured
          union_di
          union_support
          volatility
          weekend
          woman
          wt_a
        }
      }
    }
  `)

  return data.allSurveyData
}