import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Calculator from '../components/Calculator'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "auto-workers-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      findingsIcon: file(relativePath: { eq: "findings-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      compareIcon: file(relativePath: { eq: "compare.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      aboutIcon: file(relativePath: { eq: "about.png" }) {
        childImageSharp {
          fluid(maxWidth: 70, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      site {
        siteMetadata {
          siteTitle
          siteDescription
          author
          siteUrl
          siteImage
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" data={data.site} />
      <Img fluid={data.bannerImage.childImageSharp.fluid} style={{ maxHeight: '450px' }} alt='employment quality home banner image' />
      <div className='container'>
        <section className='my-4'>
          <h1 className='text-center py-4'>What makes a job good?</h1>
          
          <p className='lead'>Employment quality is a puzzle with many different pieces. Solving it takes more than decent pay and benefits. It takes job security, autonomy, a stable work schedule, and a safe and supportive work environment.</p>
          
          <p className='lead'>These are just a few of the findings from a new study by the Project for Middle Class Renewal at the University of Illinois. The study surveyed over 3,500 workers across Illinois to figure out what matters most for how they rate their overall employment situation.</p>
          
          <p className='lead'>See how different job features influence employment quality by clicking on the sliders and buttons below. You can also read more about the study findings and methodology, or compare the quality of jobs held by different groups of workers.</p>
        </section>

        <Calculator />
        
        <div className='my-4 d-flex justify-content-center'>
          <section className='col-9'>
            <div className='row align-items-center py-3'>
              <div style={{ width: '75px', height: '75px' }}>
                <Img fluid={data.findingsIcon.childImageSharp.fluid} alt='findings icon' />
              </div>
              <div className='col'>
                <p className='pt-3 h4'><a href='findings' className='dark-link'>Read our findings</a></p>
                <p>What else makes a job good? How do different pieces of the employment quality puzzle fit together?</p>
              </div>
            </div>
            
            <div className='row align-items-center py-3'>
              <div style={{ width: '75px', height: '75px' }}>
                <Img fluid={data.compareIcon.childImageSharp.fluid} alt='compare workers icon' />
              </div>
              <div className='col'>
                <p className='pt-3 h4'><a href='compare' className='dark-link'>Compare worker groups in Illinois</a></p>
                <p>How does job quality compare for different groups of workers in Illinois? See job characteristics broken down by gender, race, age, industry, and other groups.</p>
              </div>
            </div>
            
            <div className='row align-items-center py-3'>
              <div style={{ width: '75px', height: '75px' }}>
                <Img fluid={data.aboutIcon.childImageSharp.fluid} alt='about icon' />
              </div>
              <div className='col'>
                <p className='pt-3 h4'><a href='about' className='dark-link'>About</a></p>
                <p>How was this research conducted? What do the numbers represent?</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
