import React from 'react'

export default function IndicatorGroup ({ children, cardTitle, icon }) {
  return (
    <div className='card h-100'>
      <div className='p-3'>
        <div className='d-flex flex-row align-items-center'>
          <div style={{ width: '40px', height: '40px' }}>{icon}</div>
          <p className='mb-0 ml-3 h3'>{cardTitle}</p>
        </div>
        {children}
      </div>
    </div>
  )
}