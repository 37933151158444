import React from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'


export default function IndicatorSlider ({ label, value, step, min=1, max=5, onChange, marks, image, Tooltip=null }) {
  const displayLabel = marks[value] ? marks[value].label : value
  
  const header = (<label><span className='h5'>{label}:</span><span className='text-secondary'> {displayLabel}</span></label>)

  return (
    <div className='px-4 py-3'>
      <div className='d-flex justify-content-between'>
        { 
          Tooltip 
            ? <Tooltip>{header}</Tooltip>
            : header
        }
      </div>

      <Slider
        value={value}
        step={step ? step : null}
        min={min}
        max={max}
        onChange={onChange}
        marks={marks}
        ariaLabelForHandle={label}
      />
    </div>
  )
}