import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import SolidGauge from 'highcharts/modules/solid-gauge'
import Accessibility from 'highcharts/modules/accessibility'


export default function Gauge ({ value }) {
  HighchartsMore(Highcharts)
  SolidGauge(Highcharts)
  Accessibility(Highcharts)
  
  const gaugeOptions = {
    chart: {
      type: 'solidgauge',
      backgroundColor: 'transparent',
      width: 500,
    },
    title: 'Employment Quality',
    pane: {
      center: ['50%', '80%'],
      size: '100%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc'
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      stops: [
        [0.1, '#DF5353'], // red
        [0.68, '#DDDF0D'], // yellow
        [1, '#55BF3B'] // green
      ],
      lineWidth: 0,
      tickWidth: 1,
      tickInterval: 10,
      tickPosition: 'outside',
      title: {
        y: 70
      },
      labels: {
        y: 0,
        distance: 15
      },
      plotLines: [
        {
          value: 68,
          label: {
            text: 'average',
            x: -25,
            y: 25,
          },
          zIndex: 5,
          dashStyle: 'Dash',
          color: '#000000'
        }
      ]
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true
        }
      },
      line: {
        series: [{
          data: [68]
        }]
      }
    },
    series: [
      {
        name: 'Employment Quality',
        data: [value],
        dataLabels: {
          format:
              '<div style="text-align:center">' +
              '<span style="font-size:25px">{y}</span><br/>' +
              '</div>'
        },
      }
    ],
    
    accessibility: {
      description: `A gauge visualization showing an employment quality score of ${value}`
    },
    responsive: {
      rules: [
        {
          condition: {
            callback: () => {
              if (window.innerWidth < 500) {
                return true
              }
            }
          },
          chartOptions: {
            chart: {
              width: 300,
              height: 320,
              spacingRight: 25,
              spacingLeft: 25
            },
            yAxis: {
              plotLines: [
                {
                  value: 68,
                  label: {
                    text: 'average',
                    x: -35,
                    y: 15,
                  },
                  zIndex: 5,
                  dashStyle: 'Dash',
                  color: '#000000'
                }
              ]
            }
          }  
        }
      ]
    }
  }

  return (
    <div className='col d-flex justify-content-center flex-column align-items-center' style={{ marginTop: '-60px' }} aria-label={`A gauge visualization showing an employment quality score of ${value}`}>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'chart'}
        options={gaugeOptions}
      />
    </div>
  )
}
