import React from 'react'
import ReactTooltip from 'react-tooltip'

export default function AutonomyTooltip ({ children }) {
  return(
    <>
      <button style={{ all: 'unset', cursor: 'pointer' }} data-tip data-for='autonomyTooltip'>
        {children}
      </button>
      
      <ReactTooltip id='autonomyTooltip' place='top' effect='solid' backgroundColor='black' >
        Survey question asks: "How much freedom do you have to decide how to do your own work?
      </ReactTooltip>
    </>
  )
}