import React, { useState, useEffect } from 'react'
import * as d3 from 'd3'

import { useCoefficients } from '../hooks/useCoefficients'
import { useSurveyData } from '../hooks/useSurveyData'

import IndicatorSlider from '../components/IndicatorSlider'
import IndicatorRadio from '../components/IndicatorRadio'
import IndicatorGroup from '../components/IndicatorGroup'
import Gauge from '../components/Gauge'

import PayIcon from '../components/icons/PayIcon'
import JobSecurityIcon from '../components/icons/JobSecurityIcon'
import WorkHoursIcon from '../components/icons/WorkHoursIcon'
import WorkEnvironmentIcon from '../components/icons/WorkEnvironmentIcon'

import PayTooltip from '../components/tooltips/PayTooltip'
import AutonomyTooltip from '../components/tooltips/AutonomyTooltip'

export default function Calculator () {
  const coefficients = useCoefficients()
  const surveyData = useSurveyData()
  
  const [controlledEmploymentQuality, setControlledEmploymentQuality] = useState(0)
  const [salary, setSalary] = useState(3.6020599913279625)
  const [ptoDays, setPtoDays] = useState(10)
  const [hoursPreference, setHoursPreference] = useState(2)
  const [jobSecurity, setJobSecurity] = useState(4)
  const [healthAndSafety, setHealthAndSafety] = useState(4)
  const [coworkerSupport, setCoworkerSupport] = useState(4)
  const [freedom, setFreedom] = useState(3)
  const [promotion, setPromotion] = useState(3)
  const [workDaysVary, setWorkdaysVary] = useState(false)
  const [retirementBenefits, setRetirementBenefits] = useState(false)
  const [isUnionized, setIsUnionized] = useState(false)
  
  useEffect(() => {
    const controlledEmploymentQuality = calculateControlledEmploymentQuality(coefficients)
    setControlledEmploymentQuality(controlledEmploymentQuality)
  }, 
  [salary, 
    ptoDays, 
    hoursPreference,
    jobSecurity,
    healthAndSafety,
    coworkerSupport,
    freedom,
    promotion,
    workDaysVary,
    retirementBenefits,
    isUnionized,
    coefficients]
  )
  
  function calculateControlledEmploymentQuality (coefficients) {
    const excluded = [
      'Intercept',
      'log_pay',
      'bene_retire',
      'underemp',
      'overemp',
      'daysvary',
      'pto_prop35',
      'Secure',
      'Safe',
      'Support',
      'Autonomy',
      'Opportunities5',
      'union_di'
    ]
    
    const remainingFactorsAvg = getRemainingFactorsAvg(excluded, coefficients)

    const offset = ( 
      coefficients.Intercept +
      coefficients.bene_retire * (retirementBenefits ? 1 : 0) +
      coefficients.underemp * (hoursPreference === 1 ? 1 : 0) +
      coefficients.overemp * (hoursPreference === 3 ? 1 : 0) +
      coefficients.daysvary * (workDaysVary ? 1 : 0) +
      coefficients.pto_prop35 * ptoDaysProportion(ptoDays) +
      coefficients.Secure * jobSecurity +
      coefficients.Safe * healthAndSafety +
      coefficients.Support * coworkerSupport +
      coefficients.Autonomy * freedom +
      coefficients.Opportunities5 * promotion +
      coefficients.union_di * (isUnionized ? 1 : 0) +
      coefficients.log_pay * salary +
      remainingFactorsAvg
    )

    return offset * 10
  }
  
  function ptoDaysProportion (ptoDays) {
    if (ptoDays > 35) {
      return 1
    } else {
      return (ptoDays / 35)
    }
  }

  function getRemainingFactorsAvg (exclude, coefficients) {
    return d3.sum(
      Object.entries(coefficients).filter(
        ([key, coefficient]) => !exclude.includes(key)
      ).map(
        ([key, coefficient]) => coefficient * d3.mean(surveyData.nodes, (d) => Number(d[key])))
    )
  }
  
  const salaryLog10 = {
    3: {
      label: '$1,000',
      style: { left: '3.5%' }
    },
    3.3010299956639813: {
      label: '$2,000'
    },
    3.6020599913279625: {
      label: '$4,000'
    },
    3.9030899869919438: {
      label: '$8,000'
    },
    4.204119982655925: {
      label: '$16,000',
      style: { left: '95.5%' }
    }
  }

  return (
    <section className='border rounded px-4 bg-light mb-3 pb-2'>
      <h2 className='sr-only'>Employment Quality Calculator</h2>
      <div className='mb-4'>
        {
          controlledEmploymentQuality && <Gauge value={parseInt(controlledEmploymentQuality)} />
        }
      </div>
      
      <div style={{ marginTop: '-50px'}}>
        <div className='row'>
          <div className='col mb-1 pr-1 pl-0'>
            <IndicatorGroup cardTitle='Compensation' icon={<PayIcon />}> 
              <IndicatorSlider 
                label='Monthly pay'
                value={salary}
                min={3}
                max={4.204119982655925}
                marks={salaryLog10}
                onChange={setSalary}
              />
                
              <IndicatorRadio 
                label='Retirement benefits?'
                value={retirementBenefits}
                onChange={setRetirementBenefits}
              />
              
              <IndicatorSlider
                label='Paid days off per year'
                value={ptoDays}
                step={1}
                min={0}
                max={35}
                marks={{0: 0, 7: 7, 14: 14, 21: 21, 28: 28, 35: {label: '35+', style: {left: '99%'}}}}
                onChange={setPtoDays}
                Tooltip={PayTooltip}
              />
            </IndicatorGroup> 
          </div>
          
          <div className='col mb-1 pr-0 pl-0'>
            <IndicatorGroup cardTitle='Work hours & scheduling' icon={<WorkHoursIcon />}>
              <IndicatorSlider 
                label='Weekly work hours preference'
                value={hoursPreference}
                min={1}
                max={3}
                onChange={setHoursPreference}
                // Beware! These marks are the opposite of the data.
                // In the data, 1 = Fewer and 2 = More, but the requirements
                // wanted "More" on the left side of the slider and
                // "Fewer" on the right side, so we set 1 = More and 2 = Fewer. 
                // rc-slider has no way of inverting the range like that, 
                // so this is our workaround. This only works because these 
                // values have the same effect on the total score.
                marks={{
                  1: {
                    label: 'More', 
                    style: { left: '3.5%'}
                  },
                  2: {
                    label: 'Same',
                    style: { left: '50%'}
                  },
                  3: {
                    label: 'Fewer',
                    style: { left: '97.5%' }
                  }
                }}
              />
              <IndicatorRadio 
                label='Workdays vary week to week'
                value={workDaysVary}
                onChange={setWorkdaysVary}
              />
            </IndicatorGroup>
          </div>
        </div>
        
        <div className='row'>
          <div className='col mb-1 pr-1 pl-0'>
            <IndicatorGroup cardTitle='Job security & opportunity' icon={<JobSecurityIcon />}>
              <IndicatorSlider 
                label='Job security'
                value={jobSecurity}
                onChange={setJobSecurity}
                marks={{
                  1: {
                    label: 'Not at all secure',
                    style: { left: '10.5%' }
                  },
                  2: {
                    label: 'Slightly secure',
                    style: { display: 'none' }
                  },
                  3: {
                    label: 'Moderately secure',
                    style: { left: '50%' }
                  },
                  4: {
                    label: 'Very secure',
                    style: { display: 'none' }
                  },
                  5: {
                    label: 'Extremely secure',
                    style: { left: '93.5%' }
                  }
                }}
              />
              
              <IndicatorRadio 
                label='Is the job unionized?'
                value={isUnionized}
                onChange={setIsUnionized}
              />
              
              <IndicatorSlider 
                label='Promotion opportunities'
                value={promotion}
                onChange={setPromotion}
                marks={{
                  1: {
                    label: 'Not at all likely',
                    style: { left: '9.5%' }
                  },
                  2: {
                    label: 'Not too likely',
                    style: { display: 'none' }
                  },
                  3: {
                    label: 'Moderately likely',
                    style: { left: '50%' }
                  },
                  4: {
                    label: 'Very likely',
                    style: { display: 'none' }
                  },
                  5: {
                    label: 'Extremely',
                    style: { left: '93.5%' }
                  }
                }}
              />
            </IndicatorGroup>
          </div>
          
          <div className='col mb-1 pr-0 pl-0'>
            <IndicatorGroup cardTitle='Work environment' icon={<WorkEnvironmentIcon />}>
              <IndicatorSlider
                label='Health & safety conditions'
                value={healthAndSafety}
                onChange={setHealthAndSafety}
                marks={{
                  1: {
                    label: 'Terrible',
                    style: { left: '4.5%' }
                  },
                  2: {
                    label: 'Poor',
                    style: { display: 'none' }
                  },
                  3: {
                    label: 'Fair',
                    style: { left: '50%' }
                  },
                  4: {
                    label: 'Good',
                    style: { display: 'none' }
                  },
                  5: {
                    label: 'Excellent',
                    style: { left: '94%' }
                  }
                }}
              />
              
              <IndicatorSlider
                label='Coworker support'
                value={coworkerSupport}
                onChange={setCoworkerSupport}
                marks={{
                  1: {
                    label: 'Not at all',
                    style: { left: '5.5%' }
                  },
                  2: {
                    label: 'A little',
                    style: { display: 'none' }
                  },
                  3: {
                    label: 'A moderate amount',
                    style: { left: '50%' }
                  },
                  4: {
                    label: 'A lot',
                    style: { display: 'none' }
                  },
                  5: {
                    label: 'A great deal',
                    style: { left: '92%', width: '100%' }
                  }
                }}
              />
              
              <IndicatorSlider 
                label='Autonomy'
                value={freedom}
                onChange={setFreedom}
                marks={{
                  1: {
                    label: 'None at all',
                    style: { left: '6.5%'}
                  },
                  2: {
                    label: 'A little',
                    style: { display: 'none' }
                  },
                  3: {
                    label: 'A moderate amount',
                    style: { left: '50%' }
                  },
                  4: {
                    label: 'A lot',
                    style: { display: 'none' }
                  },
                  5: {
                    label: 'A great deal',
                    style: { left: '92%', width: '100%' }
                  }
                }}
                Tooltip={AutonomyTooltip}
              />
            </IndicatorGroup>
          </div>
        </div>
      </div>
    </section>
  )
}